import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Phone"
            subTitle="(703) 751 2532"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="contact@alfanemt.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Location"
            subTitle="6121 Lincolnia Rd STE 106, Alexandria VA 22312"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3108.6086912644428!2d-77.14045905893155!3d38.81851857185874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b3ad8d942759%3A0xb73d8a74f21c3b7e!2sSyTech%20building%2C%206121%20Lincolnia%20Rd%2C%20Alexandria%2C%20VA%2022312!5e0!3m2!1sen!2sus!4v1725338777926!5m2!1sen!2sus" 
          width="600" 
          height="450" 
          allowfullscreen="" 
          loading="lazy" 
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
