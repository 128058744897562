import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import Section from '../Section';

import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';

import { pageTitle } from '../../helpers/PageTitle';


const featureListData = [
  {
    title: 'Experienced NEMT Professionals',
    subTitle:
      'Our team of dedicated drivers and transport coordinators are specially trained to ensure a safe, comfortable, and timely journey for all passengers. We prioritize professionalism and compassion in every interaction.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Comprehensive <br />Services',
    subTitle:
      'We offer a full spectrum of non-emergency medical transport options, including wheelchair and stretcher transport, long-distance transfers, and scheduled appointments. Whether you need a ride to a local clinic or a cross-town facility, we’ve got you covered.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Patient-centered <br />Approach',
    subTitle:
      'We focus on the needs and preferences of each individual, ensuring that every transport experience is personalized and respectful. Our goal is to make every trip as smooth and stress-free as possible.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'State-of-the-art <br />Fleet',
    subTitle:
      'Our modern fleet of vehicles is equipped with advanced safety features and comfort amenities to provide the highest standard of transportation. From wheelchair accessibility to climate control, our vehicles are designed to meet diverse needs.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const funFactData = [
  { number: '5+', title: 'Years of experience |' },
  
  { number: '95%', title: 'Satisfaction rating |' },

  { number: '10000+', title: 'Trips annually |' },
  
  { number: '10+', title: 'Qualified drivers & staff |' },
  
];


export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to <br />Alfa NEMT Services"
        subTitle="Alfa NEMT is a leading provider of non-emergency medical transportation, committed to delivering dependable and compassionate service. We focus on ensuring each journey is safe, comfortable, and tailored to the specific needs of our passengers."
      />

      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        
      </Section>

    </>
  );
}
