import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
const teamData = [
  {
    imgUrl: '/images/doctors/doctor_1.png',
    department: 'Sedan',
    name: 'Sedan',
    designation: 'Standard Sedan',
    description:
      'Our standard sedans are ideal for comfortable and efficient transport for patients who can sit upright and do not require specialized equipment. These vehicles are equipped with luxury amenities to ensure a smooth ride.',
    
    category: 'Sedan',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/doctor_2.png',
    department: 'SUV',
    name: 'SUV',
    designation: 'Comfort SUV',
    description:
      'Our comfort SUVs provide extra space and a higher level of comfort for passengers who need more room. They are suitable for patients who require additional space or have medical equipment that needs to be transported.',
    
    category: 'SUV',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/doctor_3.png',
    department: 'Mini Van',
    name: 'Mini Van',
    designation: 'Family Minivan',
    description:
      'Our family minivans are designed to accommodate multiple passengers and offer ample space for mobility aids and medical supplies. Perfect for group transfers or transporting patients with caregivers.',
    
    category: 'Van',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/doctor_4.png',
    department: 'Wheelchair Van',
    name: 'Wheelchair Van',
    designation: 'Accessible Wheelchair Van',
    description:
      'Our accessible wheelchair vans are equipped with ramps or lifts to facilitate easy boarding and disembarking for wheelchair users. These vans are designed to provide a secure and comfortable experience for passengers with mobility challenges.',
    
    category: 'Van',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/doctor_5.png',
    department: 'Wheelchair Van',
    name: 'Wheelchair Van',
    designation: 'Deluxe Wheelchair Van',
    description:
      'For those requiring additional comfort and advanced features, our deluxe wheelchair vans offer enhanced amenities and more space. Ideal for longer journeys or when extra comfort is needed.',
    
    category: 'Van',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/doctor_6.png',
    department: 'Specialty Vehicle',
    name: 'Specialty Vehicle',
    designation: 'Stretcher Van',
    description:
      'Our stretcher vans are specifically designed for patients who need to lie down during transport. These vehicles come with specialized equipment to ensure safe and comfortable transport for non-ambulatory patients.',
    
    category: 'Specialty Vehicle',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/doctor_7.png',
    department: 'Specialty Vehicle',
    name: 'Specialty Vehicle',
    designation: 'Long-Distance Van',
    description:
      'Our long-distance vans are built for extended journeys and are designed to provide maximum comfort and convenience for patients traveling over longer distances.',
    
    category: 'Specialty Vehicle',
    href: '#',
  },
  
];

export default function Doctors() {
  pageTitle('Vehicles');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/doctors/banner_img.png"
        title="Choose Your <br />Vehicle Type"
        subTitle="Select from Our Range of Specialized Transport Vehicles"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      
    </>
  );
}
