import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
//import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';


import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Compassion',
    subTitle:
      'We understand that seeking transport to medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our riders at ease and every one.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent transport services to our riders. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Respect',
    subTitle:
      'We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Teamwork',
    subTitle:
      'We believe in working collaboratively with our team members and other healthcare professionals to provide comprehensive and effective care to our patients.',
  },
];

const faqData = [
  {
      title: 'What should I do in case of an emergency?',
      content: 'If you have an emergency and need immediate assistance, please call 911.',
    },
  
    {
      title: 'What types of vehicles are available?',
      content: 'We offer various levels of Non-Emergency Medical Transportation (NEMT) including taxis, non-emergency ambulances, stretcher vans, and wheelchair vans. Wheelchair vans are specifically designed for transporting individuals in wheelchairs or other mobility devices and are intended for non-emergency situations only.',
    },
    {
      title: 'Who is eligible to use NEMT?',
      content: 'NEMT services are available for individuals who do not need medical care during transport but have special needs, such as seniors or those with mobility challenges.',
    },
    {
      title: 'How are transportation trips arranged?',
  content: 'We are unable to set up trips directly. You must arrange transportation through your insurance by calling the number on the back of your insurance card.',
    },
  {
      title: 'How do I choose Alfa NEMT as my provider?',
      content: 'When booking through your insurance, specify that you prefer Alfa NEMT as your service provider.',
    },
  
    {
      title: 'How much notice is required to book a transport?',
      content: 'For optimal scheduling, please book 24 hours in advance. Out-of-state trips and insurance pre-authorizations typically require 48 hours' ,
    },
    
    
    
    {
      title: 'Can I use my own wheelchair during transport?',
      content: 'Yes, you can use your own manual or electric wheelchair. We also provide comfortable wheelchairs in all our vehicles for your convenience.',
    },
    
    {
      title: 'What are your hours of operation?',
      content: 'For the most accurate information, call us at 703 751 2532. We operate 24/7, including holidays, and are always available to assist you.',
    },
      {
      title: 'Can a caregiver or family member accompany me?',
      content: 'Yes, you are welcome to have a caregiver or family member travel with you. We also accommodate service animals at no extra charge.',
    },
    {
      title: 'Will the driver assist me inside or stay with me during my appointment?',
      content: 'Our service includes “door-through-door” and “bed-to-bed” assistance. Drivers will help you transfer from your bed to a wheelchair or stretcher and will ensure you are checked in at your appointment. If your return pickup time is uncertain, the driver will leave a business card and request that the office call us 30 minutes before your appointment ends. If needed, you can request that the driver stays with you throughout your appointment when booking the transport.',
    },
  ];
  /*
const blogData = [
  {
    title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
    thumbUrl: 'images/home_1/post_1.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Healthy Eating on a Budget: Tips and Strategies',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: 'May 4, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'The Importance of Regular Cancer Screenings and Early Detection',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
];
*/



export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="ALFA<br/> Non-Emergency<br/>  Medical Transport"
        subTitle="Reliable, Comfortable, and Caring Transport<br/>We provide safe and dependable non-emergency medical transportation to ensure you get to your appointments on time, comfortably, and stress-free."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/home-banner.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: 'Phone Number',
            subTitle: '(703) 751 2532',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Email',
            subTitle: 'contact@alfanmet.com',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Location',
            subTitle: ['6121 Lincolnia Rd #106' ,'Alexandria, VA 22312'],
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Book Now"
        btnUrl="/appointments"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>      
      {/* End Feature Section */}


      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="Alfa NEMT"
          featureList={[
            {
              featureListTitle:
                'Alfa NMET is a leading provider of non-emergency medical transport',
              featureListSubTitle:
                'At ALFA NEMT, we specialize in non-emergency medical transportation, offering reliable and compassionate service. Our experienced team and diverse fleet ensure every journey is safe, comfortable, and tailored to individual needs.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      
     
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
      
      {/* Start Blog Section 
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      
    </>
  );
}
