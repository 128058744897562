import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_1.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">LAURENCE HUBERT</h3>
                <p className="cs_heading_color mb-0">WASHINGTON, DC</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_2.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">MANUEL ALEJANDRO</h3>
                <p className="cs_heading_color mb-0">FAIRFAX, VIRGINIA</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_3.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">ABEBE KEBEDE</h3>
                <p className="cs_heading_color mb-0">VIRGINIA</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
  {activeTab === 1 && (
    <div className="cs_testimonial cs_style_1">
      <img src="/images/icons/quote.svg" alt="Icon" />
      <p>
        "I had to arrange transportation for my elderly mother, and the service
        was exceptional. The driver was punctual and very helpful, making sure
        my mother was comfortable and safely transported to her appointment. 
        The whole process was smooth and stress-free."
      </p>
      <Rating ratingNumber={5} />
    </div>
  )}
  {activeTab === 2 && (
    <div className="cs_testimonial cs_style_1">
      <img src="/images/icons/quote.svg" alt="Icon" />
      <p>
        "Using this NEMT service was a game-changer for my father’s appointments.
        The team was professional, and the vehicles were clean and well-equipped.
        They really made a difference in ensuring he got to his medical visits on
        time and safely."
      </p>
      <Rating ratingNumber={4.5} />
    </div>
  )}
  {activeTab === 3 && (
    <div className="cs_testimonial cs_style_1">
      <img src="/images/icons/quote.svg" alt="Icon" />
      <p>
        "I was impressed by the efficiency and care provided during my recent
        transport. The driver was friendly and accommodated all our needs,
        including the use of my wheelchair. I highly recommend this service for
        anyone needing reliable non-emergency medical transport."
      </p>
      <Rating ratingNumber={4.5} />
    </div>
  )}
</div>
    </div>
  );
}
